import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";

const smallProps: ConfettiProps = {
  force: 0.4,
  duration: 2200,
  particleCount: 30,
};

const useLocalStorageState = <T,>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

function App() {
  const [isExploding, setIsSmallExploding] = React.useState(false);
  const [dayCompleted, setDayCompleted] = useLocalStorageState(
    "75hard-dayCompleted",
    false
  );
  const [currentDay, setCurrentDay] = useLocalStorageState(
    "75hard-currentDay",
    1
  );
  const [checkBox, setCheckBox] = useLocalStorageState<{
    diet: boolean;
    alcohol: boolean;
    cheat: boolean;
    workout1: boolean;
    workout2: boolean;
    water: boolean;
    read: boolean;
    picture: boolean;
  }>("75hard-checkBox", {
    diet: false,
    alcohol: false,
    cheat: false,
    workout1: false,
    workout2: false,
    water: false,
    read: false,
    picture: false,
  });

  const handleCheckboxChange = (name: keyof typeof checkBox) => {
    setCheckBox((prevCheckBox) => ({
      ...prevCheckBox,
      [name]: !prevCheckBox[name],
    }));
  };

  const daycanBeCompleted = Object.values(checkBox).every((value) => value);

  const [messageApi, contextHolder] = message.useMessage();

  return (
    <Row>
      {contextHolder}
      <Col span={12} offset={6}>
        <Flex align="center" vertical>
          <Row align={"middle"} style={{ marginTop: "24px" }}>
            <Flex flex={1} justify={"space-around"} align="center">
              {/* <Button disabled>Sign Up</Button> */}
              <div>
                <Typography.Title style={{ margin: 0 }} level={3}>
                  <span>#75</span> <>Hard</> Tracker
                </Typography.Title>
              </div>
              {/* <Button>
              <QuestionCircleOutlined />
            </Button> */}
            </Flex>
          </Row>

          <Flex
            align="center"
            vertical
            justify={"center"}
            style={{ marginTop: "24px", width: 300 }}
          >
            <Card>
              <div style={{ textAlign: "center" }}>
                <Typography.Title style={{ marginBlockStart: 0 }} level={4}>
                  Day {currentDay} of 75
                </Typography.Title>
                <Divider />
              </div>

              <Space direction="vertical" size={"large"}>
                <Checkbox
                  checked={checkBox["diet"]}
                  onClick={() => handleCheckboxChange("diet")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    Follow Diet 🥗
                  </Typography.Text>
                </Checkbox>
                <Checkbox
                  checked={checkBox["alcohol"]}
                  onClick={() => handleCheckboxChange("alcohol")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    No alcohol 🍸
                  </Typography.Text>
                </Checkbox>
                <Checkbox
                  checked={checkBox["cheat"]}
                  onClick={() => handleCheckboxChange("cheat")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    No cheat meals 🍔
                  </Typography.Text>
                </Checkbox>
                <Checkbox
                  checked={checkBox["workout1"]}
                  onClick={() => handleCheckboxChange("workout1")}
                >
                  <Space>
                    <Typography.Text style={{ fontSize: "20px" }}>
                      {" "}
                      One 45 min workout outside 🏋️‍♂️
                    </Typography.Text>
                  </Space>
                </Checkbox>
                <Checkbox
                  checked={checkBox["workout2"]}
                  onClick={() => handleCheckboxChange("workout2")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    Second 45 min workout 💪
                  </Typography.Text>
                </Checkbox>

                <Checkbox
                  checked={checkBox["read"]}
                  onClick={() => handleCheckboxChange("read")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    Read 10 pages of a nonfiction, personal development-focused
                    book 📚
                  </Typography.Text>
                </Checkbox>
                <Checkbox
                  checked={checkBox["picture"]}
                  onClick={() => handleCheckboxChange("picture")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    Take a progress picture 📸
                  </Typography.Text>
                </Checkbox>
                <Checkbox
                  checked={checkBox["water"]}
                  onClick={() => handleCheckboxChange("water")}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {" "}
                    Drink One Gallon of water 💧
                  </Typography.Text>
                </Checkbox>
              </Space>
            </Card>
            {!dayCompleted && (
              <Button
                disabled={!daycanBeCompleted || isExploding}
                style={{ marginTop: "16px" }}
                onClick={() => {
                  setIsSmallExploding(!isExploding);
                  messageApi.open({
                    type: "success",
                    content: "Good job completing the day! 🎉",
                  });
                  setTimeout(() => {
                    setCurrentDay(currentDay + 1);
                    setDayCompleted(false);
                    setIsSmallExploding(false);
                    setCheckBox((checkbox) => ({
                      ...checkbox,
                      diet: false,
                      alcohol: false,
                      cheat: false,
                      workout1: false,
                      workout2: false,
                      water: false,
                      read: false,
                      picture: false,
                    }));
                  }, 500);
                }}
              >
                {isExploding && <ConfettiExplosion {...smallProps} />}
                Complete Day!
              </Button>
            )}

            {/* {dayCompleted && (
            <Button
              onClick={() => {
                setCurrentDay(currentDay + 1);
                setDayCompleted(false);
                setIsSmallExploding(false);
                setCheckBox((checkbox) => ({
                  ...checkbox,
                  diet: false,
                  alcohol: false,
                  cheat: false,
                  workout1: false,
                  workout2: false,
                  water: false,
                  read: false,
                  picture: false,
                }));
              }}
              style={{ marginTop: "16px" }}
            >
              Next Day
            </Button>
          )} */}
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
}

export default App;
